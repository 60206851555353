<template>
  <div class="container">
    <iframe v-bind:src="'../news/'+$route.params.newsid+'.html'"></iframe>
    <!-- "'../news/'+$route.params.newsid+'.html'" -->
  
    <!-- <div :id="'../news/'+$route.params.newsid+'.html'">
    123456
123{{this.$route.params.newsid}}456 
      </div> -->
   <!-- 
   
   -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  // display: flex;  
  // align-items: center;
  min-height: 600px;
  margin-top: 50px;

  justify-content:center;

  .box-title {
    display: flex;    
    // width: 80%;
    justify-content:center;


    // .title-content{
    //   box-sizing: border-box;
    //     // display: flex;
    //   flex-grow: 1;
    //   width: 100%;
    //   .title-header {//
    //     display: block;
    //     margin-top: 0;
    //   }
    //   .title-summery {//
    //     font-size: 12px;
    //     margin-top: 10px;
    //   }
    // }
    .title-content{
      width: 80%;      
      justify-content:center;
      .title-line {
        width: 100%;
        // border-bottom: 2px #999 solid;
        
        margin-top: 20px;
        // margin-bottom: 20px;
        // align-items: center;
        display: flex;

        .title-header {//
          margin-bottom: -2px;
          display: flex;
          // flex-direction: row;
          // align-items: center;
          width: 90%;
          // line-height: 32px;
          color: #333;
          // font-size: 18px;
          // font-weight: 400;
          text-shadow: none;
          font-size: 16px;
          // font-weight: bolder;
          font-family: 微软雅黑;
          // border-bottom: 2px #ac0000 solid;
        }
        .title-date {
          margin-bottom: 10px;
          display: block;
          // flex-direction: row;
          // align-items: center;
          width: 15%;
          line-height: 32px;
          color: #333;
          // font-size: 18px;
          // font-weight: 400;
          text-shadow: none;
          font-size: 16px;
          // font-weight: bolder;
          font-family: 微软雅黑;
          // border-bottom: 2px #ac0000 solid;
        }
      }
      .title-summery {
        margin-bottom: 20px;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        width: 100%;
        line-height: 32px;
        color: #333;
        // font-size: 18px;
        // font-weight: 400;
        text-shadow: none;
        font-size: 14px;
          text-align: left;
        // font-weight: bolder;
        font-family: 微软雅黑;
        // border-bottom: 2px #ac0000 solid;
      }
    }
  }
  // h4{
  //   display: block;
  //   margin-block-start: 1.33em;
  //   margin-block-end: 1.33em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  // }
}
</style>
